import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const SecondHeader = () => {
  const [toggle, setToggle] = useState(false)
  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between md:justify-center mx-auto p-4">
        <Link
          to={'/'}
          className="flex items-center space-x-3 rtl:space-x-reverse md:hidden"
        >

          <img src="/images/logo.png" className="h-14 " alt="Flowbite Logo" />
        </Link>
        <button
          data-collapse-toggle="navbar-dropdown"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-dropdown"
          aria-expanded={toggle}
          onClick={() =>
            setToggle(!toggle)


          }
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        {
          toggle &&
          <div className=" w-full md:block md:w-auto" id="navbar-dropdown">
            <ul className="flex flex-col items-center font-medium p-4 md:p-0 mt-4 gap-5 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink
                  to={'/'}
                  className={`nav-item md:bg-transparent md:p-0 `}
                  aria-current="page"
                >
                  <div className="flex justify-center items-center gap-2">
                    <h1 className="font-extrabold md:text-[22px]">Home</h1>
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={'/how-it-works'}
                  className={`nav-item md:bg-transparent md:p-0 `}
                  aria-current="page"
                >
                  <div className="flex justify-center items-center gap-2">
                    <h1 className="font-extrabold md:text-[22px]">How It Works</h1>
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={'/faq'}
                  className={`nav-item md:bg-transparent md:p-0 `}
                  aria-current="page"
                >
                  <div className="flex justify-center items-center gap-2">
                    <h1 className="font-extrabold md:text-[22px]">FAQs</h1>
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={'/contact-us'}
                  className={`nav-item md:bg-transparent md:p-0 `}
                  aria-current="page"
                >
                  <div className="flex justify-center items-center gap-2">
                    <h1 className="font-extrabold md:text-[22px]">Contact Us</h1>
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={'/getstart'}
                  className={`nav-item md:bg-transparent md:p-0 `}
                  aria-current="page"
                >
                  <div className="flex justify-center items-center gap-2">
                    <h1 className="font-extrabold md:text-[22px]">Get Started</h1>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        }
      </div>
    </nav>
  );
}

export default SecondHeader;
