/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaPinterest } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

import { FaLeaf } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="bg-black">
        <div className="flex justify-around flex-wrap py-16 px-3">
          <div>
            <img src="/images/footerlogo.png" alt="fg" />
            <div>
      <p className="text-2xl lg:text-[17px] text-white myFirstFont ">National Team Member</p>
      <p className="text-2xl lg:text-[17px] text-white myFirstFont lg:leading-[10px]">Production Tracking</p>
      <p className="mt-3 text-white">Keeping track of production at the farm!</p>
       </div>
            <div className=" flex gap-3 mt-4">
              <div className="bg-[#88111B] rounded-full p-3">
                <FaTwitter color="white" size={"22px"} />
              </div>
              <div className="bg-[#88111B] rounded-full p-3">
                <FaFacebook color="white" size={"22px"} />
              </div>
              <div className="bg-[#88111B] rounded-full p-3">
                <FaPinterest color="white" size={"22px"} />
              </div>
              <div className="bg-[#88111B] rounded-full p-3">
                <FaInstagram color="white" size={"22px"} />
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-white font-bold text-[22px]  leading-[40px]">
              Explore
            </h1>
            <div className="bg-[#88111B] w-20 h-1 mt-3"></div>
            <NavLink to={"/"}>
              <div className="flex gap-2 items-center mt-6">
                <FaLeaf color="white" />
                <h1 className="text-[#A5A49A] text-[17px] leading-[24px] font-medium">
                  Home
                </h1>
              </div>
            </NavLink>

            <NavLink to={"/how-it-works"}>
              {" "}
              {/* Update the 'to' prop for each link accordingly */}
              <div className="flex gap-2 items-center mt-4">
                <FaLeaf color="white" />
                <h1 className="text-[#A5A49A] text-[17px] leading-[24px] font-medium">
                  How it works
                </h1>
              </div>
            </NavLink>

            <NavLink to={"/faq"}>
              <div className="flex gap-2 items-center mt-4">
                <FaLeaf color="white" />
                <h1 className="text-[#A5A49A] text-[17px] leading-[24px] font-medium">
                  FAQs
                </h1>
              </div>
            </NavLink>

            <NavLink to={"/contact-us"}>
              <div className="flex gap-2 items-center mt-4">
                <FaLeaf color="white" />
                <h1 className="text-[#A5A49A] text-[17px] leading-[24px] font-medium">
                  Contact Us
                </h1>
              </div>
            </NavLink>

            <NavLink to={"/getstart"}>
              <div className="flex gap-2 items-center mt-4">
                <FaLeaf color="white" />
                <h1 className="text-[#A5A49A] text-[17px] leading-[24px] font-medium">
                  Get Started
                </h1>
              </div>
            </NavLink>
          </div>
          <div>
            <h1 className="text-white font-bold text-[22px]  leading-[40px]">
              Contact
            </h1>
            <div className="bg-[#88111B] w-20 h-1 mt-3"></div>
            <div className="flex gap-2 items-center mt-6">
              <FaPhoneAlt color="#88111B" />
              <a
                href="tel:7579718119"
                className="text-[#A5A49A] text-[17px] leading-[24px] font-medium"
              >
                757-971-8119
              </a>
            </div>

            <div className=" flex gap-2 items-center mt-4">
              <MdEmail color="#88111B" />
              <h1 className=" text-[#A5A49A] text-[17px] leading-[24px] font-medium">
                sales@barnyardtracker.com
              </h1>
            </div>
            <div className=" flex gap-2 items-center mt-4">
              <FaLocationDot color="#88111B" />
              <h1 className=" text-[#A5A49A] text-[17px] leading-[24px] font-medium">
                USA
              </h1>
            </div>

            <div className=" flex gap-2 items-center mt-4">
              <form>
                <div className="flex">
                  <label
                    htmlFor="search-dropdown"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Your Email Address
                  </label>

                  <div
                    id="dropdown"
                    className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdown-button"
                    >
                      <li>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Shopping
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Images
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          News
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Finance
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="relative w-full">
                    <input
                      type="search"
                      id="search-dropdown"
                      className="block p-2.5 md:w-96 w-52 z-20 text-sm text-gray-900 bg-gray-50  rounded-lg rounded-s-gray-100  border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                      placeholder=" Your Email Address"
                      required=""
                    />
                    <button
                      type="submit"
                      className="absolute top-0 end-0 p-2.5 h-full text-sm font-medium text-white bg-[#C83425] rounded-e-lg border  hover:bg-[#C83425]  focus:outline-none dark:hover:bg-blue-700 "
                    >
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bg-[#88111B] py-8 flex justify-around px-2">
          <div>
            {" "}
            <h1 className="text-white font-semibold text-[16px] leading-[34px]">
              © All Copyright 2024 by{" "}
              <a href="https://hnhsofttechsolutions.com " target="_blank">
                {" "}
                HNH Soft Tech Solutions
              </a>
            </h1>
          </div>
          <div className="flex gap-2">
            {" "}
            <h1 className="text-white font-semibold text-[16px] leading-[34px]">
              Terms of Use
            </h1>
            <h1 className="text-white font-semibold text-[16px] leading-[34px]">
              Privacy Policy
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
