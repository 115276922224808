const HomeBaner=()=>{
    return(
        <>
        <div style={{ backgroundImage: 'url(images/banner1.jpg)' }} className="bg-cover bg-center h-screen flex items-center relative">
  {/* Background Overlay */}
  <div className="absolute inset-0 bg-black opacity-30 z-10"></div>

  <div className="flex items-center px-5  lg:px-52 relative z-20">
    <div>
      <p className="font-semibold text-[18px] text-white montserrat">Welcome to Barnyard Tracker</p>
      <p className="text-2xl lg:text-[85px] text-white myFirstFont lg:leading-[110px]">National Team Member</p>
      <p className="text-2xl lg:text-[85px] text-white myFirstFont lg:leading-[110px]">Production Tracking</p>
      <p className="mt-3 text-white">Keeping track of production at the farm!</p>
      {/* <p className="mt-2 text-white">Lorem ipsum dolor sit amet</p> */}
      <button className="rounded-lg button-down mt-4">Sign up now</button>
    </div>
  </div>
</div>

        </>
    )
}
export default HomeBaner