import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const OurFarm = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <div className="flex justify-center  bg-[#D7D9DD] py-5">
        <div className="container flex justify-center py-2 px-4 md:px-10">
          <div className="grid md:grid-cols-2 gap-8 grid-cols-1 justify-center items-center">
            <div data-aos="fade-right">
              <img src="/images/agri1.png" alt="bg" />
            </div>
            <div data-aos="fade-left" data-aos-duration="1000">
              <h1 className="md:text-[28px] font-medium md:leading-[41px]">
                Our Farm Benefits
              </h1>
              <h1 className=" font-normal text-[#88111B] md:text-[54px] myFirstFont md:leading-[65px]">
                Why Choose Agrios Market
              </h1>
              <p className=" font-medium text-[18px] md:leading-[34px] text-[#878680] md:mt-4 mt-2">
                Lorem ipsum dolor sit amet consectetur. Pharetra sodales ipsum
                eu nullam quis. Sed felis pellentesque id facilisis lorem turpis
                eget id.
              </p>
              <div className="flex flex-wrap flex-col md:gap-12 mt-8 " >
                <div className=" flex gap-2 flex-wrap" data-aos="fade-left" data-aos-duration="1500">
                  <img
                    src="/images/2correct.png"
                    className="w-[35px] h-[35px]"
                    alt="f"
                  />
                  <div>
                    <p className="text-[#88111B] font-extrabold text-[22px] md:leading-[32px]">
                      Quality Organic Food
                    </p>
                    <p className="text-[18px] font-medium leading-[24px] text-[#878680]">
                      There are variation You need to be sure there is
                    </p>
                    <p className="text-[18px] font-medium leading-[24px] text-[#878680]">
                      There are variation You need to be sure there is
                    </p>
                  </div>
                </div>
                <div className=" flex gap-2 flex-wrap" data-aos="fade-left" data-aos-duration="2200">
                  <img
                    src="/images/3correct.png"
                    className="w-[35px] h-[35px]"
                    alt="f"
                  />
                  <div>
                    <p className="text-[#88111B] font-extrabold text-[22px] md:leading-[32px]">
                      Quality Organic Food
                    </p>
                    <p className="text-[18px] font-medium leading-[24px] text-[#878680]">
                      There are variation You need to be sure there is
                    </p>
                    <p className="text-[18px] font-medium leading-[24px] text-[#878680]">
                      There are variation You need to be sure there is
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap flex-col md:gap-12 mt-8 "data-aos="fade-left" data-aos-duration="2500">
                <div className=" flex gap-2 flex-wrap"  >
                  <img
                    src="/images/1correct.png"
                    className="w-[35px] h-[35px]"
                    alt="f"
                  />
                  <div>
                    <p className="text-[#88111B] font-extrabold text-[22px] md:leading-[32px]">
                      Quality Organic Food
                    </p>
                    <p className="text-[18px] font-medium leading-[24px] text-[#878680]">
                      There are variation You need to be sure there is
                    </p>
                    <p className="text-[18px] font-medium leading-[24px] text-[#878680]">
                      There are variation You need to be sure there is
                    </p>
                  </div>
                </div>
              </div>

              <button className=" rounded-lg button-down mt-4">
                Discover More
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="relative h-screen">
        <img
          src="/images/footeri.png"
          alt="df"
          className=" absolute w-full h-full object-cover z-1"
        />
        <div className="relative z-10 py-20">
          <div className="full md:w-1/2 m-auto text-center space-y-5">
            <h1 className="text-[#88111B] font-bold text-lg md:text-5xl myFirstFont ">
              Drones are the future of agriculture
            </h1>
            <p className="text-[20px] text-[#4B4B4B]">
              Get a bird's eye view of your fields and get a more accurate{" "}
              <br />
              estimation of crop yield.
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default OurFarm;
